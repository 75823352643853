<template>
  <v-data-table :headers="headers" :items="drivers" class="elevation n-1" :search="search" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat  dark color="#598639" >
        <v-toolbar-title>{{ $t("Drivers") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black"  v-show="$auth.user().roles === '1'" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Driver") }}
            </v-btn>
          </template>
          <v-card style="border-style: solid;  border-color: white;" >
            <v-toolbar color="black" dark>
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-account</v-icon> <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-toolbar-title>
            </v-toolbar>

            <v-card-text >
              <v-form v-model="valid" refs="form">
              <v-container class="mt-3" >
                <v-row>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.user_data1"
                      :label="$t('Prg. Driver ID')"
                      :counter="50"
                      :rules="rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.plate"
                      :label="$t('Device Driver ID')"
                      counter="80"
                      :rules="rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.name"
                      :label="$t('Driver ID')"
                      :counter="45"
                      :rules="rules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.string3"
                      :label="$t('Driver Description 1')"
                      :counter="45"
                    ></v-text-field>
                  </v-col>
                   <v-col cols="12" >
                    <v-text-field
                      dense
                      outlined
                      v-model="editedItem.string4"
                      :label="$t('Driver Description 2')"
                      :counter="45"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                      <v-checkbox
                        v-model="editedItem.status"
                        :label="$t('Active')"
                        color="indigo darken-3"
                        :true-value="2"
                        :false-value="1"
                        
                      ></v-checkbox>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
              </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn color="warning" @click="close"> {{$t('Cancel')}} </v-btn>
               <v-spacer></v-spacer>
              <v-btn color="vinland" class="white--text" @click="save" :disabled="!valid" :loading="loading2"> {{$t('Save')}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{$t('Cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{$t('OK')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.driver_id_type_required="{ item }">
        <div v-if="item.driver_id_type_required == 5">
          <v-icon    color="teal"> mdi-car </v-icon>
          <v-icon  color="teal"> mdi-account</v-icon>
        </div>
        <div v-else>
          <v-icon    color="purple"> mdi-car </v-icon>
        </div>
    </template>
    
    <template v-slot:item.type="{ item }">
        <v-icon v-if="item.type === 3"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else  color="red"> mdi-cancel</v-icon>
    </template>
    <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status > 1"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else  color="red"> mdi-cancel</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon v-show="$auth.user().roles === '1'"  color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon v-show="$auth.user().roles === '1'"  color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "drivers",

  data() {
    return {
        dialog: false,
        dialogDelete: false,
        drivers: [],
        loading: false,
        loading2: false,
        valid: false,
        rules: [
          v => v !== ''||  this.$t('Item is required'),
          v => v.length <= 45 || this.$t('A maximum  characters exceeded')
        ],
        editedItem: {
          MeansString: '',
          user_data1: '',
          plate: '',
          name: '',
          string2: '',
          string3: '',
          string4:'',
          type: 4,
          status: 2,
          fleet_id: 0,
          cust_id: '0'
        },
        defaultItem: {
          MeansString: '',
          user_data1: '',
          plate: '',
          name: '',
          string2: '',
          string3: '',
          string4:'',
          type: 4,
          status: 2,
          fleet_id: 0,
          cust_id: '0'
        },
        editedIndex: -1,
        search: '',
        headers: [
          {
            text: this.$t('Prg. Driver ID'),
            align: 'start',
            sortable: true,
            value: 'user_data1',
          },
          {
            text: this.$t('Device Driver ID'),
            align: 'start',
            sortable: true,
            value: 'plate',
          },
          {
            text: this.$t('Driver ID'),
            align: 'center',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$t('Driver Desc. 1'),
            align: 'center',
            sortable: true,
            value: 'string3',
          },
          {
            text: this.$t('Driver Desc. 2'),
            align: 'center',
            sortable: true,
            value: 'string4',
          },
          {
            text: this.$t('Active'),
            align: 'center',
            sortable: true,
            value: 'status',
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: 'actions',
          },
        
        
        ]
        
    }
  },
  methods: {
      async initialize () {
        try {
          this.loading = true
          const res = await this.$http.get("/driver")
          this.drivers = res.data
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
      // this.drivers = [
      //   {
      //     user_data1: '878952',
      //     plate: 'Josef',
      //     name: 'Plavec',
      //     string2: 'popis 1',
      //     string3: 'popis 2',
      //     string4:'popis 2',
      //     type: 4,
      //     status: 1,
       
          
      //   },
      //   {
      //     user_data1: '459112557',
      //     plate: 'George',
      //     name: 'Bentley',
      //     string2: 'popis 1',
      //     string3: 'popis 2',
      //     string4:'popis 2',
      //     type: 4,  //4 = driver
      //     status: 2,  // int 2 = active     < 2 inactive
          
      //   },
      // ]
    },
    
    editItem (item) {
      this.editedIndex = this.drivers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.drivers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      let res = {};
      try {
          res = await this.$http.delete(`/driver/${this.editedItem.id}`)
          this.drivers.splice(this.editedIndex, 1)
          this.closeDelete()
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.closeDelete()
        } 
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    validate () {
        return this.$refs.form.validate()
    },

    async save () {
      let res = null
      if (this.editedIndex > -1) {
        try {
          this.loading2 = true
          this.editedItem.MeansString = this.editedItem.user_data1
          res = await this.$http.put("/driver", this.editedItem)
          Object.assign(this.drivers[this.editedIndex], this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
        
      } else {
        try {
          this.loading2 = true
          this.editedItem.MeansString = this.editedItem.user_data1
          if (this.drivers.some(driver => driver.user_data1 === this.editedItem.user_data1)) {
            throw new Error(this.$t('Same string exists'));
          }
          await this.$http.post("/driver", this.editedItem)
          this.drivers.push(this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error || ""})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      }
      this.close()
    },
  },

  computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$t('Add new Driver') : this.$t('Edit Driver')
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      editedItem: {
        handler() {
          this.$refs.form.validate()
        },
        deep: true
      }
    },

    created () {
      this.initialize()
    },
  
    

}
</script>

<style scoped>
.vinland {
  background-color: #196619 !important;
}
</style>
